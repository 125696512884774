<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div v-if="message" class="alert alert-danger mb-10" role="alert">
            {{ message }}
          </div>
          <Form
            @submit="handleSubmit"
            :validation-schema="schema"
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
          >
            <div class="mb-5">
              <label for="">Role Name</label>
              <Field
                type="text"
                placeholder="name"
                name="name"
                class="form-control bg-transparent"
                v-model="formInput.name"
              />
              <ErrorMessage
                name="username"
                class="text-danger error-feedback"
              />
            </div>
            <hr />
            <label for="">Select Permission</label>
            <Field
              type="select"
              as="select"
              multiple
              name="permissions"
              class="form-control bg-transparent"
              v-model="formInput.permissions"
            >
              <option
                v-for="row in permissions"
                :key="row.id"
                :value="row.name"
                :selected="
                  formInput.permissions && formInput.permissions.includes(drink)
                "
              >
                {{ row.name }}
              </option>
            </Field>

            <div class="row mt-5">
              <div class="col">
                <BaseTable
                  ref="permissionTable"
                  :service="'permission'"
                  :columns="permissionColumns"
                  :allowCreate="false"
                  :initialLimit="9999"
                >
                  <template #header-right>
                    <h5 class="mt-5">Daftar Permission</h5>
                  </template>
                </BaseTable>
              </div>
              <div class="col">
                <BaseTable
                  ref="menuTable"
                  :service="'menu'"
                  :columns="menuColumns"
                  :allowCreate="false"
                  :initialLimit="9999"
                >
                  <template #header-right>
                    <h5 class="mt-5">Daftar Menu</h5>
                  </template>
                </BaseTable>
              </div>
            </div>
            <div>
              <button
                class="btn btn-secondary"
                @click="$router.push({ name: 'role-list' })"
              >
                Back
              </button>
              <button
                :disabled="loading"
                type="submit"
                id="kt_sign_in_submit"
                class="btn btn-success"
              >
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress" v-show="loading">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  >
                  </span>
                </span>
              </button>
            </div>
          </Form>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import { toRaw } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Service from "../../../services/base.service";
import BaseTable from "/src/components/BaseTable.vue";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    BaseTable,
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required("Name is required!"),
      permissions: yup.array(),
    });

    return {
      loading: false,
      message: "",
      formInput: { name: "", permissions: [] },
      permissionColumns: [{ field: "name", title: "Name" }],
      menuColumns: [
        { field: "label", title: "Name" },
        { field: "url", title: "Url" },
      ],
      schema,
      permissionSelected: [],
      menuSelected: [],
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    }
  },
  methods: {
    getPermissionSelected() {
      this.$refs.permissionTable.getSelectedRows();
    },
    handleSelectedRows(value) {
      this.permissions = value;
      this.formInput.permissions = value.map((r) => {
        return r.name;
      });
    },
    async fetchData() {
      this.loading = true;
      try {
        const BaseService = new Service("role");
        const { data } = await BaseService.getData(this.$route.params.id);
        this.formInput = data;
        // this.$refs.datatable.selectRow(1)
        this.permissionSelected = data.permissions.map((r) => {
          return r.id;
        });
        this.menuSelected = data.menus.map((r) => {
          return r.id;
        });
        const rawDataPermission = toRaw(
          this.$refs.permissionTable.getRawData()
        );
        rawDataPermission.forEach((r, i) => {
          if (this.permissionSelected.includes(r.id)) {
            this.$refs.permissionTable.setSelectedRows(i);
          }
        });
        const rawDataMenu = toRaw(this.$refs.menuTable.getRawData());
        rawDataMenu.forEach((r, i) => {
          if (this.menuSelected.includes(r.id)) {
            this.$refs.menuTable.setSelectedRows(i);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(data) {
      this.loading = true;
      try {
        const permission = this.$refs.permissionTable.getSelectedRows();
        const menus = this.$refs.menuTable.getSelectedRows();
        // this.permissions = permission;
        data.permissions = permission.map((r) => r.name);
        data.menus = menus.map((r) => r.id);
        const BaseService = new Service("role");
        const { message } = this.$route.params.id
          ? await BaseService.updateData(this.$route.params.id, data)
          : await BaseService.createData(data);
        this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
